<template>
  <div class="register">
    <div class="header">
      <div class="c-box">
        <div class="logo"><img src="~@/assets/login/logo.png"></div>
      </div>
    </div>
    <div class="content conter">
      <div class="infowenzi">
        <div class="formdiv">
          <div class="logoICO">
            <img src="~@/assets/login/loginlogo.png">
            <span v-show="step==false">欢迎注册！</span>
          </div>
          <el-form
            v-show="step==false"
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
          >
            <el-form-item prop="custUserName" class="inputdiv">
              <el-input
                v-model="ruleForm.custUserName"
                type="text"
                auto-complete="off"
                placeholder="请输入登录名"
                prefix-icon="el-icon-user"
              />
            </el-form-item>
            <el-form-item prop="passWord" class="inputdiv">
              <el-input
                v-model="ruleForm.passWord"
                type="passWord"
                auto-complete="off"
                placeholder="6-16位密码，区分大小写"
                prefix-icon="el-icon-lock"
              />
            </el-form-item>
            <el-form-item prop="affirmPasswd" class="inputdiv">
              <el-input
                v-model="ruleForm.affirmPasswd"
                type="passWord"
                auto-complete="off"
                placeholder="确认密码"
                prefix-icon="el-icon-lock"
              />
            </el-form-item>
            <el-form-item prop="regTel" class="inputdiv">
              <el-input v-model="ruleForm.regTel" placeholder="请输入手机号" class="input-with-select">
                <el-select slot="prepend" v-model="ruleForm.area" placeholder="请选择">
                  <el-option v-for="item in phone" :key="item.tel" :label="'+'+item.tel" :value="item.tel" />
                </el-select>
              </el-input>
            </el-form-item>
            <!--              <el-form-item prop="verificationCode" class="inputdiv">
               <el-input
                 type="text"
                 v-model="ruleForm.verificationCode"
                 auto-complete="off"
                 placeholder="请输入验证码"
                 style="width:calc(100% - 110px)"
               ></el-input>
               <el-button style="width:100px;float:right" @click="getCode">获取验证码</el-button>
             </el-form-item> -->
            <el-form-item prop="custName" class="inputdiv">
              <el-input
                v-model="ruleForm.custName"
                type="text"
                auto-complete="off"
                placeholder="公司名称"
                prefix-icon="el-icon-user"
              />
            </el-form-item>
            <div class="dengru">
              <el-button
                type="primary"
                @click="userRegister()"
              >注 册</el-button>
              <span class="rtgthzhu" @click="goto('/login')">使用已有账号登录</span>
            </div>
          </el-form>
          <div v-show="step==true" class="toTime">
            <div class="elicon">
              <i class="el-icon-check" />
            </div>
            <div class="eltitle">
              <p>欢迎使用</p>
              <p>佳裕达仓储管理系统</p>
              <p>即将登录 {{ time }} S</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import phone from '@/config/phone.js';
import { registerNew, verification } from '@/api/user.js';
import { Message } from 'element-ui';
export default {

  name: 'Register',
  props: {

  },
  data: function() {
    var validatePass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请确认密码'));
      } else if (value !== this.ruleForm.passWord) {
        return callback(new Error('密码不一致'));
      } else {
        callback();
      }
    };
    return {
      step: false,
      time: 3,
      phone,
      ruleForm: { area: '86' },
      rules: {
        custUserName: [
          { required: true, message: '请输入请输入登录名', trigger: 'blur' }
        ],
        passWord: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 16, message: '长度在 6 到 16 个字符,区分大小写', trigger: 'blur' }
        ],
        affirmPasswd: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        regTel: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        // verificationCode: [
        //   { required: true, message: '请输入验证码', trigger: 'blur' }
        // ],
        custName: [
          { required: true, message: '请输入公司名', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    userRegister() { // 注册
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          const reqForm = {
            ...this.ruleForm,
            custNameAbbr: this.ruleForm.custUserName
          };
          registerNew(reqForm).then(result => {
            try {
              if (result.data.code == 200) {
                this.$message.success('注册成功，请登录');
                this.$store.dispatch('newName', result.data.userName);
                this.step = true;
                this.setTime();
              } else {
                this.$message.error({
                  message: result.data.msg
                });
              }
              this.loading = false;
            } catch (err) {
              this.loading = false;
              console.log(err);
            }
          }).catch(err => {
            this.$message.error(err.data.msg);
          });
        }
      });
    },
    getCode() { // 验证码
      if (!this.ruleForm.regTel) {
        Message.warning({
          message: '请输入手机号'
        });
      } else {
        verification({ phone: this.ruleForm.regTel, params: true }).then(result => {
          console.log(result);
        });
      }
    },
    goto(route) {
      this.$router.push({ path: route });
    },
    setTime() {
      setTimeout(() => {
        if (this.time <= 0) {
          this.$router.push({ path: '/home' });
        } else {
          this.time--;
          this.setTime();
        }
      }, 1000);
    }
  }
};

</script>

<style scoped>
.header{height:80px;overflow:hidden;}
.logo{width:80px;margin-top:15px;}
.logo img{width:100%;}
.content{height:calc(100vh - 80px);background:url('~@/assets/bg.png') no-repeat center top;}
.logoICO{width:210px;margin:50px auto 0;text-align:center;}
.logoICO img{width:100%;}
.logoICO span{font-size:16px;font-weight:bold;color:#333;margin-top:5px;}
.step-box{width:80%;margin:0 auto;display:flex;justify-content:center;align-items:center;margin:70px auto}
.stepAc>p:nth-child(1){background:#31AF3D;color:#fff !important;border-color:#31AF3D !important}
.stepAc>p:nth-child(2){color:#31AF3D !important}
.step-box>div{display:flex;flex:1;flex-direction:column;justify-content:center;align-items:center;}
.step-box>div>p:nth-child(1){width:34px;height:34px;line-height:34px;text-align:center;border-radius:17px;border:1px solid #ccc;color:#ccc}
.step-box>div>p:nth-child(2){font-size:12px;margin-top:12px;color:#ccc}
.step-box>div>p{margin:0;}
.elicon{width: 100px;
    height: 100px;
    line-height: 100px;
    border-radius: 50px;
    background: #31AF3D;
    margin: 100px auto 50px;
    color: #ffff;
    text-align: center;
    font-size: 45px;}
.eltitle p{font-size:16px;color:#333;line-height:30px;text-align:center;margin:0}
.eltitle p:nth-child(3){font-size:10px;color:#999;}
.toTime{width:80%;margin:0 auto;}
.infowenzi {
  width: 500px;
  height: 440px;
  border: 1px solid rgba(225, 225, 225, 0.2);
  border-radius: 6px;
  display: flex;
  flex-direction: row; /* 子元素横向排列 */
  justify-content: center; /* 相对父元素水平居中 */
  align-items: center; /*  子元素相对父元素垂直居中 */
}
.formdiv {
  width: 590px;
  min-height:675px;
  background-color: #fff;
}
.logo {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  text-align: center;
  line-height: 90px;
}
.inputdiv {
  width: 80%;
  margin: 0 auto;
  margin-top: 30px;
}
.dengru {
  width: 80%;
  height: 50px;
  margin: 30px auto 60px;
}
.dengru .el-button {
  width: 100%;
  background: #3874ee;
  border-radius: 25px;
  background-image: linear-gradient(#5BA8FF,#2A70FF);
}
.rtgthzhu {
  cursor:pointer;
  font-size: 10px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #F42F2F;
  margin-right:10px;
  margin-top: 20px;
  float: right;
}
.radio {
  font-size: 10px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
</style>
